require('slick-carousel');
const Cookies = require('js-cookie');

// Windowロード・リサイズ時
$(window).on('load resize', function(){
  let windowWidth= $(window).width();
  globalFlag(windowWidth);
  headerHeight();
  setConditionOfCouponTab(); 
});
$(window).on('load', function(){
  let windowWidth= $(window).width();
  gnavSubnav(windowWidth);
  modalAutoVisible();
  displayCampaign();
  setFeaturesToCookie();
});

/**
 * Check to display the campaign
 */
function displayCampaign()
{
    let campaigns = $('.js-display-campaign');

    campaigns.each(function(){
        let dateFrom = $(this).attr('data-date-from');
        let dateTo = $(this).attr('data-date-to');

        // Parse the provided date and time strings
        let fromDate = moment.tz(dateFrom, "Asia/Tokyo");

        // Get the current date and time in JST
        let now = moment().tz("Asia/Tokyo");

        if (! dateTo) {
            if (now.isAfter(fromDate)) {
                // Default hidden
                $(this).removeClass('campaign-block');
            } else {
                $(this).remove();
            }
        } else {
            let toDate = moment.tz(dateTo, "Asia/Tokyo");

            // Compare the dates
            if (now.isBetween(fromDate, toDate)) {
                // Default hidden
                $(this).removeClass('campaign-block');
            } else {
                $(this).remove();
            }
        }
    })
}

//
//  ヘッダーの高さを取得
//
window.headerHeight = function(){
  let scoreHeaderHeight = $(".js-header-height").innerHeight();
  $('body').css('padding-top', scoreHeaderHeight + 'px');
}

//
// グローバル判定
//
function globalFlag(windowWidth){
  let flagLogin = $('html').attr('data-login');
  // PC SP
  if(windowWidth > 768){
    $('html').addClass('pc');
    $('html').removeClass('sp');
  } else {
    $('html').addClass('sp');
    $('html').removeClass('pc');
  }
  // ログインの有無
  if(flagLogin == 'true'){
    $('html').addClass('login');
  } else {
    $('html').removeClass('login');
  }
}


//
// テキストをコピーする
//
$(document).on('click', '.js-copy-text', function (){
  let txtCopyURL = $(this).attr('data-page-url');
  let flagCopyType = $(this).attr('data-copy-type');
  if (navigator.clipboard) {
    navigator.clipboard.writeText(txtCopyURL);
  }

  if(flagCopyType !== "text"){
    $(this).append('<span class="popup">URLをコピーしました</span>');
    const elePopup = $('.js-copy-text .popup');
    elePopup.css('visibility', 'visible');
    elePopup.animate({
      opacity: 1
    }, 200);
    setTimeout(function (){
      elePopup.hide();
    }, 2000);
  } else {

  }
});

//
// 商品詳細の画像数でMVの表示を変更
//
window.initProductImageMore = function(){
  const numImageCount = $(".js-product-image-more").find('.grid-item');
  if(numImageCount.length > 5){
    $(".js-product-image-more").addClass('show-product-more');
    numImageCount.each(function (e){
      // FIXME:(DONE))↓型一致でお願いします（e === 4）原則型一致です。
      if(e === 4){
        $(this).find('.js-trigger-modal').attr('data-modal-anchor', '0');
      }
    });
  }
}


//
// 商品詳細のSlickを実行
//
// slickの実行
window.initSlickImageDetail = function(windowWidth){
  if(windowWidth < 576){
    $('.slick-image').on('init', function(event, slick){
      let numSlideCount = slick.slideCount,
        numCurrentSlideCount = 1;
      $(this).append('<div class="slide-count"><span class="current">' + numCurrentSlideCount + '</span>/<span class="all">' + numSlideCount + '</span></div>')
    });

    setTimeout(function (){
      $('.slick-image').slick({
        dots: false,
        infinite: true,
        speed: 300,
        touchThreshold: 20
        //prevArrow: '<span class="btn-arrow btn-sm left"><i class="icon icon-arrow-white"></i></span>',
        //nextArrow: '<span class="btn-arrow btn-sm right"><i class="icon icon-arrow-white"></i></span>'
      });
    }, 100);

    $('.slick-image').on('afterChange', function(event, slick, currentSlide){
      if(currentSlide >= slick.slideCount){
        currentSlide = 0;
      }
      $(this).find('.slide-count .current').text(currentSlide + 1);
    });
  }
}

//
// 商品リストのグリッド変更
//
// slickの実行
window.initSlickImage = function(windowWidth, productList = false){
    if (productList) {
        $('.slick-image').slick({
            dots: false,
            infinite: true,
            speed: 300,
            touchThreshold: 20,
            prevArrow: '<span class="btn-arrow btn-sm left"><i class="icon icon-arrow-white"></i></span>',
            nextArrow: '<span class="btn-arrow btn-sm right"><i class="icon icon-arrow-white"></i></span>'
        });
    }
}

// Cookieをセット
window.setGridNo = function(){
  let gridNo = Cookies.get('grid-no');

  $('.js-grid').removeClass('on-1');
  $('.js-grid').removeClass('on-2');

  if(gridNo){
    $('.js-grid').addClass('on-' + gridNo);
  } else {
    $('.js-grid').addClass('on-1');
  }
}

// 切り替えボタンクリック時の挙動
$('.js-grid .js-grid-trigger').on('click', function (){
  let scoreGrid = $(this).parents('.js-grid').attr('data-grid-no');
  let scoreGridCurrent = $(this).attr('data-grid-current');
  // FIXME:(DONE)↓型一致
  if(scoreGridCurrent === "2"){
    Cookies.set('grid-no', scoreGridCurrent);
    $(this).parents('.js-grid').removeClass('on-1');
    $(this).parents('.js-grid').addClass('on-2');
    $('.slick-image').slick('slickSetOption', {}, true);
  // FIXME:(DONE)↓型一致
  } else if(scoreGridCurrent === "1"){
    Cookies.set('grid-no', scoreGridCurrent);
    $(this).parents('.js-grid').removeClass('on-2');
    $(this).parents('.js-grid').addClass('on-1');
  }
});

//
// Toggleの展開
//
// タグリストの高さが規定値以上かどうか判定し、規定値以上の場合はmoreボタン等表示する
window.toggleTagMore = function(windowWidth){
  let eleWrap = $('.js-toggle-tag-more');

  eleWrap.each(function (){
    let eleHeightTarget = $(this).find('.js-target-height'),
      maxHeight,
      currentMaxHeight,
      flagSpHeight = eleHeightTarget.attr('data-sp-height'),
      innerHeight = $(this).find('.js-target-all-height').innerHeight();

    if(windowWidth > 768 || flagSpHeight == 'same'){
      maxHeight = eleHeightTarget.attr('data-max-height');
      currentMaxHeight = maxHeight;
    } else {
      maxHeight = eleHeightTarget.attr('data-max-height');
      currentMaxHeight = maxHeight * 2;
    }

    if(currentMaxHeight < innerHeight){
      $(this).addClass('on');
      eleHeightTarget.css('maxHeight', currentMaxHeight + 'px');
      eleHeightTarget.attr('data-current-max-height', currentMaxHeight);
    }
  });

}

// ブロック数に応じて高さが基地一以上かどうか判定し、規定値以上の場合はmoreボタン等表示する
window.toggleBlockMore = function(windowWidth){
  let eleToggle = $('.js-toggle-block-more');

      eleToggle.each(function (){
        let eleEachToggle = $(this),
            txtToggleText = eleEachToggle.attr('data-toggle-text'),
            eleMaxHeight = eleEachToggle.find('.js-target-height'),
            eleAllHeight = eleEachToggle.find('.js-target-all-height'),
            eleBlocks = eleEachToggle.find('.js-block-height'),
            numMaxBlocks = Number(eleMaxHeight.attr('data-max-block')),
            numAllBlocks = eleBlocks.length,
            numMaxHeight = 0,
            eleEach,
            i = 0;
        // Toggleの有無を判定
        if(numMaxBlocks < numAllBlocks){
          eleEachToggle.addClass('on');
          while (i < numMaxBlocks){
            eleEach = $(eleBlocks[i]);
            if(i + 1 == numMaxBlocks){
              eleEach.addClass('edge');
              if(!eleEachToggle.hasClass('more-button-on')){
                eleEach.after('<div class="button text-center py-xxs"><button id="more-details" type="button" class="js-trigger-toggle-more btn btn-white-bordered-5 btn-md-2 btn-square btn-toggle">' + txtToggleText + '</button></div>');
              }
              eleEachToggle.addClass('more-button-on');
              let numButtonHeight = $(eleEach).next('.button').innerHeight();
              // 最後のボックスの高さを計算し合計
              numMaxHeight += $(eleEach).innerHeight();
              numMaxHeight += numButtonHeight;
            } else {
              // 指定個数-1個目までの高さを取得し合計
              numMaxHeight += $(eleEach).innerHeight();
            }
            i++;
          }

          // 最大の高さを設定
          if(!eleToggle.hasClass('expanded')){
            eleMaxHeight.css('maxHeight', numMaxHeight + 'px');
            eleMaxHeight.attr('data-current-max-height', numMaxHeight);
          } else {
            eleMaxHeight.attr('data-current-max-height', numMaxHeight);
          }

        }
      });

}

// moreボタンをクリックした際の処理
$(document).on('click', '.js-toggle-more .js-trigger-toggle-more', function (){
  let eleHeightTarget = $(this).parents('.js-toggle-more').find('.js-target-height'),
      eleAllHeight = $(this).parents('.js-toggle-more').find('.js-target-all-height');
  if($(this).hasClass('expanded')){
    $(this).removeClass('expanded');
    $(this).parents('.js-toggle-more').removeClass('expanded');
    let text = $(this).attr('data-text');
    $(this).text(text);
    let maxHeight = eleHeightTarget.attr('data-current-max-height');
    eleHeightTarget.css('maxHeight', maxHeight + 'px');
  } else {
    $(this).addClass('expanded');
    $(this).parents('.js-toggle-more').addClass('expanded');
    let text = $(this).text();
    $(this).attr('data-text', text);
    $(this).text("閉じる");
    innerHeight = eleAllHeight.innerHeight();
    eleHeightTarget.css('maxHeight', innerHeight + 'px');
  }


});

//
//  商品大カテゴリーのアコーディオン展開関連
//
// 商品サブカテゴリーのアコーディオン位置設定
window.setCategoryToggle = function(){

  // DOM.classをリセット
  $('.js-category-toggle .block-toggle-subcategory-wrap').remove();
  $('.js-category-toggle .edge').removeClass('edge');

  let containerWidth = $('.js-category-toggle').innerWidth(),
      CountAllItem = $('.js-category-toggle > .js-category-toggle-item').length,
      itemWidth = $('.js-category-toggle > .js-category-toggle-item').innerWidth(),
      countCol = Math.floor(containerWidth / itemWidth),
      countRow = Math.floor(CountAllItem / countCol),
      contNextCol = 0;
      i = 1;

  // エッジの要素にclassを設置
  while (i <= countRow){
    contNextCol = contNextCol + countCol;
    $('.js-category-toggle > .js-category-toggle-item:nth-of-type(' + contNextCol + ')').addClass('edge');
    i++;
  }
  $('.js-category-toggle > .js-category-toggle-item:nth-of-type(' + CountAllItem + ')').addClass('edge');

  // edgeの後ろに展開用DOMを追加
  $('.js-category-toggle > .edge').each(function (){
    $(this).after("<li class='block-toggle-subcategory-wrap col-item'><div class='js-target-category-height'></div></li>")
  });
}

// 商品サブカテゴリーのクリック時の処理
$('.js-category-toggle .js-trigger-category-toggle').on('click', function (){
  let htmlSubcat = $(this).next('.js-target-category-toggle').html();
  let subCatBlock = $(this).parent('.js-category-toggle-item').nextAll('.block-toggle-subcategory-wrap');

  if($(this).hasClass('on')){
    // 直後のサブカテゴリー展開用のDOMを閉じる
    $(this).removeClass('on');
    subCatBlock.each(function (){
      $(this).css('height', 0);
      $(this).removeClass('on');
      return false;
    });
  } else {

    // 全てのサブカテゴリーをリセット
    $('.js-category-toggle .js-trigger-category-toggle').removeClass('on');
    $('.js-category-toggle .block-toggle-subcategory-wrap').css('height', 0);
    $(this).addClass('on');

    // 直後のサブカテゴリー展開用のDOMを開く
    subCatBlock.each(function (){
      $(this).children('.js-target-category-height').html(htmlSubcat);
      $(this).css('display','block');
      let currentSubCategoryHeight = $(this).children('.js-target-category-height').outerHeight();
      $(this).css('height', currentSubCategoryHeight + 8);
      $(this).addClass('on');
      return false;
    });
  }
});

// 商品サブカテゴリーの閉じるボタンクリック時の処理
$(document).on("click", ".js-category-toggle .js-trigger-close", function () {
  $(this).parents('.block-toggle-subcategory-wrap').removeClass('on');
  $('.js-category-toggle .js-trigger-category-toggle').removeClass('on');
  $(this).parents('.block-toggle-subcategory-wrap').css('height', 0);
});

//
// スクロールの上下を判定してグローバルメニューを出し入れする
//
let startPositon = 200, winScrollTop = 0;
$(window).on('scroll',function(){
  winScrollTop = $(this).scrollTop();
  let scoreHeaderHeight = $(".js-header-fixed").innerHeight() + 6;
  if (winScrollTop >= startPositon) {
    $('body').removeClass('global-nav-on');
    $('.js-header-fixed').css({
      'transform': 'translateY(-' + scoreHeaderHeight + 'px)'
    });
  } else {
    $('body').addClass('global-nav-on');
    $('.js-header-fixed').css({
      'transform': 'translateY(0px)'
    });  }
  if(winScrollTop < 200){
    startPositon = 200;
  } else {
    startPositon = winScrollTop;
  }
});

//
// 検索フィールドのフォーカス時に検索ボタンを表示する
//
$(".js-input-active").blur(function(){
  let input = $(this).val();
  if(input){
    $(this).addClass('active');
  }else{
    $(this).removeClass('active');
  }
});

//
// お気に入りボタンのon/off切り替え
//
$(".js-toggle-favorite").on('click',function (){
  let txtOnMessage = $(this).attr('data-text'),
    txtOffMessage = $(this).text(),
    eleTargetText = $(this).find('.js-follow-text'),
    flagOn = $(this).hasClass('on');
  if(flagOn === true){
    $(this).removeClass('on');
    $(this).attr('aria-pressed','false');
  } else {
    $(this).addClass('on');
    $(this).attr('aria-pressed','true');
  }
  if(txtOnMessage){
    eleTargetText.text(txtOnMessage);
    $(this).attr('data-text', txtOffMessage);
  }
});

//
// 商品サムネイルスライダー関連
//
//　横幅をウィンドウ端まで伸ばす
window.fitRightWidth = function(windowWidth) {
  windowWidth = $('main.main').width();
  $('.js-thumb-width').each(function (){
    let containerWidth = $(this).parents('.js-thumb-width-container').innerWidth();
    let thumbRightWidth = $(this).parents('.js-thumb-width-right').innerWidth();
    let thumbWidth = thumbRightWidth + ((windowWidth - containerWidth) / 2);
    $(this).css('width', thumbWidth);
  });
}

// ロード時の横幅の調整、ボタンの挙動、data属性の設定
window.sliderThumbInit = function(){
  $('.js-thumb-slider-parent').each(function (){

    // アイテム数が少ない場合、スライダーボタンを非表示にする
    let numSliderParent = $(this).innerWidth();

    let eleSliderWrap = $(this).find('.js-thumb-slider-target').children('li');
    let numCurrentSliderWrap = 0;
    eleSliderWrap.each(function (){
      numCurrentSliderWrap += $(this).innerWidth();
    });
    if(numSliderParent >= numCurrentSliderWrap){
      $(this).children('.js-thumb-slider-buttons').css('visibility','hidden');
    } else {
      $(this).children('.js-thumb-slider-buttons').css('visibility','visible');
    }

    // 先頭アイテムにdata属性を付与
    $(this).children('.js-thumb-slider-target').attr('data-current-count', 1);
    $(this).children('.js-thumb-slider-target').attr('data-prev-count', 0);
    $(this).children('.js-thumb-slider-target').css('transform','');

    // prevスライダーボタンをdisabledにする
    $(this).find('.js-trigger-thumb-slider.left').addClass('disabled');
  });
}

// Sliderボタンクリック時
$(function(){
  $('body').on('click', '.js-trigger-thumb-slider', function (){
    ThumbSliderEvent(this);
  });
});

window.ThumbSliderEvent = function(me){
  let eleSliderParent = $(me).closest('.js-thumb-slider-parent')
  let eleSliderWrap = $(me).closest('.js-thumb-slider-parent').find('.js-thumb-slider-target');
  let eleSliderItem = $(me).closest('.js-thumb-slider-parent').find('.js-thumb-slider-target > li');
  let parentWidth = eleSliderParent.innerWidth(); // 表示領域横幅の取得
  // FIXME:(DONE)↓未使用変数？
  let itemWidth = eleSliderItem.innerWidth(); // 各商品横幅の取得
  let itemCountAll = eleSliderItem.length; // 商品の個数を取得
  let itemCountDisplay = Math.floor(parentWidth / itemWidth); // 表示領域の個数を取得
  let itemCountStop = itemCountAll - itemCountDisplay; // スライドできる最終アイテムの番号
  let itemCurrentCount = eleSliderWrap.attr('data-current-count'); // 現在先頭のスライド番号を取得
  let itemPrevCount = eleSliderWrap.attr('data-prev-count'); // 1つ前の先頭のスライド番号を取得

  if($(me).hasClass("left")){
    eleSliderWrap.css('transform','translate(-' + ((itemWidth * itemPrevCount) - itemWidth) +'px)'); // スライドする
    itemCurrentCount = Number(itemCurrentCount) - 1; // 現在先頭のスライド番号を再設定
    itemPrevCount = Number(itemPrevCount) - 1; // ひとつ前の先頭のスライド番号を再設定
  } else if($(me).hasClass("right")) {
    eleSliderWrap.css('transform','translate(-' + (itemWidth * itemCurrentCount) +'px)'); // スライドする
    itemCurrentCount = Number(itemCurrentCount) + 1; // 現在先頭のスライド番号を再設定
    itemPrevCount = Number(itemPrevCount) + 1; // ひとつ前の先頭のスライド番号を再設定
  }

  // 先頭より前、最後にヒットしたときの処理
  eleSliderParent.find('.js-trigger-thumb-slider').removeClass("disabled");
  if(itemCurrentCount <= 1){
    $(me).addClass("disabled");
  } else if(itemCurrentCount > itemCountStop){
    $(me).addClass("disabled");
  }

  // htmlにスライド番号を記録
  eleSliderWrap.attr('data-current-count', itemCurrentCount);
  eleSliderWrap.attr('data-prev-count', itemPrevCount);
};


//
// ランキングのタブ関連
//
// ロード時にタブを非表示にする
window.tabInit = function() {
  $('.js-tab-content').hide();
  $('.js-tab-content.active').show();
}

// タブ切り替え
$('.js-trigger-tab').on('click', function (){
  triggerTab($(this))
});

window.triggerTab = function($this) {
  let itemTabID = $this.attr('aria-controls');
  let eleTabParent = $this.parents('.js-tab-parent');
  let eleAllTabContent = eleTabParent.find('.js-tab-content');
  let eleCurrentTabContent = eleTabParent.find('#' + itemTabID + '.js-tab-content');
  let eleAllTabs = eleTabParent.find('.js-trigger-tab');

  // スライダー横幅のリセット
  eleAllTabContent.css('opacity','0');
  eleAllTabContent.show();
  eleTabParent.find('.js-thumb-width').css('width','auto');
  setTimeout(function (){
    let windowWidth= $(window).width();
    fitRightWidth(windowWidth);
    setTimeout(function (){
      eleAllTabContent.css('opacity','1');
    },100);
  },100);

  // アクティブな要素を有効化
  eleAllTabContent.hide();
  eleAllTabContent.removeClass('active');
  eleAllTabs.removeClass('active');
  eleAllTabs.attr('aria-selected','false');
  eleCurrentTabContent.addClass('active');
  eleCurrentTabContent.show();
  $this.addClass('active');
  $this.attr('aria-selected','true');
}

// ランキングタブを左右矢印で移動
$(document).on("keydown", ".js-trigger-tab", function(e){

  let index       =   null;
  let selector    =   ".js-trigger-tab";

  if ( e.keyCode === 37 ){
    index   =   $(selector).index(this);
    if (index > 0){
      $(selector).eq(index-1).focus();
    }
    return;
  }
  if ( e.keyCode === 39 ){
    index   =   $(selector).index(this);
    if (index < $(selector).length - 1 ){
      $(selector).eq(index+1).focus();
    }
    return;
  }
});

//
// 商品詳細ページのSticky関連
//
// 商品詳細ページの右エリアにSticky値を設定
window.stickyProdctDetailRight = function(windowWidth){
  let eleStickyContainer = $('.js-sticky');
  let eleStickyRight = eleStickyContainer.find($('.js-sticky-right'));
  let eleStickyItem = eleStickyRight.find($('.js-sticky-item'));
      let  numContainerHeight = eleStickyContainer.innerHeight();
      if(numContainerHeight){
        let numRightHeightEnd = eleStickyContainer.offset().top + numContainerHeight;
        let numRightHeight = eleStickyRight.innerHeight();
        let numRightHeightStart = eleStickyRight.offset().top + numRightHeight;
        let numRightWidth = eleStickyRight.width();
        eleStickyContainer.attr('data-sticky-start-height', numRightHeightStart);
        eleStickyContainer.attr('data-sticky-end-height', numRightHeightEnd);
        if(windowWidth > 768){
          // PCでの挙動
          eleStickyItem.css('width', numRightWidth + 'px');
        } else {
          // SPでの挙動
          eleStickyItem.css('width', 100 + '％');
        }
      }
}

// スクロール時の処理
$(window).scroll(function (){
  let windowWidth= $(window).width(),
      eleStickyContainer = $('.js-sticky'),
      eleStickyRight = eleStickyContainer.find($('.js-sticky-right')),
      eleStickyItem = eleStickyRight.find($('.js-sticky-item')),
      numStickyItemHeight = eleStickyItem.innerHeight(),
      numCurrentScroll = $(this).scrollTop(),
      // FIXME:(DONE)attr()の返値はstringなので`+ 200`だと文字列結合になるはずです。意図した挙動でしょうか？
      numRightHeightStart = Number($('.js-sticky').attr('data-sticky-start-height')) + 200,
      numRightHeightEnd = $('.js-sticky').attr('data-sticky-end-height') - numStickyItemHeight;

  if(windowWidth > 992){
    // PCでの挙動
    if(numCurrentScroll > numRightHeightStart && numCurrentScroll < numRightHeightEnd){
      eleStickyItem.css({
        'visibility': 'visible',
        'opacity': 1
      });
    } else {
      eleStickyItem.css({
        'visibility': 'collapse',
        'opacity': 0
      });
    }

  } 
  // else {
  //   // SPでの挙動
  //   if(numCurrentScroll > numRightHeightStart){
  //     eleStickyItem.addClass('sticky-on');
  //     eleStickyItem.css({
  //       'visibility': 'visible',
  //       'opacity': 1
  //     });
  //   } else {
  //     eleStickyItem.removeClass('sticky-on');
  //     eleStickyItem.css({
  //       'visibility': 'collapse',
  //       'opacity': 0
  //     });
  //   }
  // }

});


//
// 商品詳細モーダルの展開
//
// モーダルの展開
$(document).on('click', '.js-trigger-modal', function() {
  let $this = $(this);
  openModal($this);
});

window.openModal = function($this) {
  let windowWidth = $(window).width(),
    numModalNo = $this.attr('data-modal'),
    numModalAnchorNo = $this.attr('data-modal-anchor'),
    eleTargetModal = $("#" + numModalNo + ".js-target-modal"),
    flagModalType = eleTargetModal.attr('data-type'),
    flagModalLayer = $this.attr('data-modal-layer'),
    numScrollTop = $(window).scrollTop(),
    numBgZindex, numModalZindex;
  if (flagModalLayer == 2) {
    numBgZindex = 520;
    numModalZindex = 530;
  } else {
    numBgZindex = 500;
    numModalZindex = 510;
  }

  // フラグがない場合、モーダルを展開
  if (flagModalType == 'subnav') {
    $('html').addClass('modal-subnav');
    $this.addClass('on');
    $('body').append('<div class="js-trigger-modal-close modal-backdrop subnav" data-close-modal=' + numModalNo + ' style=z-index:99;></div>');
    if (windowWidth <= 768) {
      $('html').css('overflowY', 'hidden');
      $('body').css({
        'position': 'fixed',
        'width': 100 + '%',
        'top': -numScrollTop + 'px'
      });
      $('html').attr('data-scroll-position', numScrollTop);
    }
  } else if (flagModalType == 'alert') {
    $('html').css('overflowY', 'hidden');
    if (windowWidth <= 768) {
      $('body').css({
        'position': 'fixed',
        'width': 100 + '%',
        'top': -numScrollTop + 'px'
      });
    }
    $('html').attr('data-scroll-position', numScrollTop);
    eleTargetModal.css({
      'visibility': 'visible',
      'opacity': 1,
      'height': 100 + '%',
      'marginTop': 0 + 'px',
      'zIndex': numModalZindex,
      'overflowY': 'auto'
    });
    $('body').append('<div class="js-trigger-modal-close modal-backdrop" data-close-modal=' + numModalNo + ' style=z-index:' + numBgZindex + ';></div>');
  } else if (flagModalType == 'product-mv') {
    $('html').css('overflowY', 'hidden');
    eleTargetModal.addClass('modal-on');
    if (windowWidth <= 768) {
      $('body').css({
        'position': 'fixed',
        'width': 100 + '%',
        'top': -numScrollTop + 'px'
      });
    }
    $('html').attr('data-scroll-position', numScrollTop);
    eleTargetModal.css({
      'marginTop': '200px'
    });
    setTimeout(function () {
      eleTargetModal.css({
        'visibility': 'visible',
        'opacity': 1,
        'height': 100 + '%',
        'zIndex': numModalZindex,
        'overflowY': 'auto',
        'marginTop': '0px'
      });
    }, 100);
    $('body').append('<div class="js-trigger-modal-close modal-backdrop" data-close-modal=' + numModalNo + ' style=z-index:' + numBgZindex + ';></div>');
  } else if (flagModalType == 'filter'){
    $('html').css('overflowY', 'hidden');
    eleTargetModal.addClass('modal-on');
    if(windowWidth <= 768){
      $('body').css({
        'position': 'fixed',
        'width': 100 + '%',
        'top': -numScrollTop + 'px'
      });
    }
    $('html').attr('data-scroll-position', numScrollTop);
    setTimeout(function (){
      eleTargetModal.css({
        'visibility': 'visible',
        'opacity': 1,
        'height': 100 + '%',
        'zIndex': numModalZindex,
        'overflowY': 'auto'
      });
    },100);
    $('body').append('<div class="js-trigger-modal-close modal-backdrop" data-close-modal=' + numModalNo + ' style=z-index:' + numBgZindex + ';></div>');
   } else if (flagModalType == 'hamburger') {
      $('html').css('overflowY', 'hidden');
      $('body').addClass('hamburger-on');
      eleTargetModal.addClass('modal-on');
      $('html').attr('data-scroll-position', numScrollTop);
      if(windowWidth <= 768) {
        setTimeout(function (){
            eleTargetModal.css({
            'visibility': 'visible',
            'opacity': 1,
            'height': 100 + '%',
            'zIndex': numModalZindex,
            'overflowY': 'auto',
            'marginTop': '0px'
            });
        },100);
        $('body').append('<div class="js-trigger-modal-close modal-backdrop" data-close-modal=' + numModalNo + ' style=z-index:' + numBgZindex + ';></div>');
      } else {
        setTimeout(function (){
            eleTargetModal.css({
            'visibility': 'visible',
            'opacity': 1,
            'height': 'auto',
            'zIndex': 90,
            'overflowY': 'auto',
            'marginTop': '0px'
            });
        },100);
        $('body').append('<div class="js-trigger-modal-close modal-backdrop" data-close-modal=' + numModalNo + ' style=z-index:' + 89 + ';></div>');
      }
    } else {
    $('html').css('overflowY', 'hidden');
    eleTargetModal.addClass('modal-on');
    if(windowWidth <= 768){
      $('body').css({
        'position': 'fixed',
        'width': 100 + '%',
        'top': -numScrollTop + 'px'
      });
    }
    $('html').attr('data-scroll-position', numScrollTop);
    eleTargetModal.css({
      'marginTop': '-48px'
    });
    setTimeout(function (){
      eleTargetModal.css({
        'visibility': 'visible',
        'opacity': 1,
        'height': 100 + '%',
        'zIndex': numModalZindex,
        'overflowY': 'auto',
        'marginTop': '0px'
      });
    },100);
    $('body').append('<div class="js-trigger-modal-close modal-backdrop" data-close-modal=' + numModalNo + ' style=z-index:' + numBgZindex + ';></div>');
  }

  // Sticky要素が消える問題
  if (windowWidth <= 768) {

    // 商品詳細のカートに入れる
    const flagStickyProduct = $('.js-sticky-item').hasClass('sticky-on');
    if(flagStickyProduct){
      $('.js-sticky-item').addClass('sticky-on-fixed');
    }

    // グローバルナビ
    const flagStickyGnav = $('body').hasClass('global-nav-on');
    if(!flagStickyGnav){
      $('body').addClass('global-nav-on');
    }
  }

  // アンカーが入っていれば、アンカー位置を取得
  if(numModalAnchorNo){
    let eleCurrentAnchor = eleTargetModal.find("#anchor-" + numModalAnchorNo);
    let numAnchorPos = eleCurrentAnchor.position().top;
    eleTargetModal.scrollTop(numAnchorPos);
  }

  // グローバルナビのモーダル展開時の処理
  globalNaviModalInit(eleTargetModal);
  
  // trigger event to flexible customize
  eleTargetModal.trigger('shown.modal');
}

// クリック後、モーダルを自動的に非表示にする
$('.js-modal-auto-hidden').on('click', function (){
  setTimeout(function (){
    $('.js-trigger-modal-close').click();
  }, 2000);
});

// モーダル子要素をクリックしても親に伝搬しないようにする
$('.js-stop-prop').on('click' , event => {
  event.stopPropagation();
})

// モーダルを閉じる
$(document).on('click', '.js-trigger-modal-close', function (){
  let txtModalName = $(this).attr('data-close-modal')
    numScrollPosition = $('html').attr('data-scroll-position');
  closeModal(txtModalName, numScrollPosition);
});

window.closeModal = function(txtModalName, numScrollPosition){

  $('html').removeClass('modal-subnav');
  $('.modal-backdrop[data-close-modal=' + txtModalName + ']').remove();

  // モーダルがまだ存在するかどうかを確認
  let flagModalOn = $('.modal-backdrop').length;
  if(flagModalOn == "0"){
    $('.js-input-search2').blur();
    $('html').css('overflowY', 'visible');
    $('body').css({
      'position': '',
      'width': '',
      'top': ''
    });
    $(window).scrollTop(numScrollPosition);
    $('#' + txtModalName + '.js-target-modal').css({
      'opacity': 0,
      'overflowY': 'hidden'
    });
    setTimeout(function (){
      $('#' + txtModalName + '.js-target-modal').css({
        'visibility': 'collapse',
      });
    }, 320);
    $('#' + txtModalName + '.js-target-modal').removeClass('modal-on');
  } else {
    $('#' + txtModalName + '.js-target-modal').css({
      'visibility': 'collapse',
      'opacity': 0
    });
    $('#' + txtModalName + '.js-target-modal').removeClass('modal-on');
  }

  // Stickyの解除
  $('.js-sticky-item').removeClass('sticky-on-fixed');
  $('body').removeClass('global-nav-on-2');

  // キーワードモーダル展開時に閉じた場合
  setTimeout(function (){
    $('.js-search-history-modal').css({
      'visibility': 'collapse',
      'opacity': 0
    });
  }, 320);

  // ハンバーガーメニューを閉じた場合
  if(txtModalName == 'gnav-hamburger'){
    $('body').removeClass('hamburger-on');
  }

  // アンカーが入っていれば、アンカー位置をクリア
  $('.js-target-modal').scrollTop(0);

  // モーダル内ページをクリア
  setTimeout(function (){
    reinitPageSlide(txtModalName);
  }, 320);

}


//
// ページ内スライド
//
// クリア処理
function reinitPageSlide(txtModalName){
  $('.js-trigger-gnav-subnav').removeClass('on');
  $('.js-slide-pages .js-slide-pages-inner').removeAttr('style');
  $('.js-slide-pages .js-page-item').removeAttr('style');
  $('.js-page-item').removeAttr('style');
  $('.js-page-item').attr('data-current-position','');
  $('.js-slide-pages .js-page-item').removeClass('show');
  $('.js-slide-pages .js-page-item.first-page').addClass('show');
  $('#' + txtModalName + '.js-target-modal').removeAttr('style');
  $('#' + txtModalName + '.js-target-modal').attr('data-flag-level','');
  $('#' + txtModalName + '.js-target-modal').find('.active').removeClass('active');
  $('.js-trigger-modal').css('pointerEvents', 'none');
  setTimeout(function (){
    $('.js-trigger-modal').css('pointerEvents', 'auto');
  },400);
}

// クリック時の処理
$('.js-trigger-page-slide').on('click', function (){
  let elePageWrap = $(this).parents('.js-slide-pages'),
      elePageWrapInner = elePageWrap.children('.js-slide-pages-inner'),
      // FIXME:(DONE)↓未使用変数
      numNextPageID = $(this).attr('data-page-id'),
      eleCurrentPageItem = elePageWrapInner.find('.js-page-item.show'),
      eleNextPageItem = elePageWrapInner.find('#' + numNextPageID),
      numCurrentPageHeight = eleCurrentPageItem.innerHeight(),
      numNextPageHeight = eleNextPageItem.innerHeight(),
      flagPageType = elePageWrap.attr('data-page-type');

  // 現在の高さをセット
  elePageWrapInner.css('height', numCurrentPageHeight);

  // アニメーション・スライドタイプによって処理を分岐
  if(flagPageType == 'slide') {

    // 次ページを表示
    eleNextPageItem.animate(
      {left: '0%'},
      {
        duration: 300,
        complete: function () {
          eleNextPageItem.addClass('show');
          $(this).css('left', '0%');

          elePageWrapInner.animate(
            {height: numNextPageHeight + 'px'},
            {duration: 300}
          );
        }
      }
    );

    // 現ページを非表示表示
    eleCurrentPageItem.animate(
      {left: '-100%'},
      {
        duration: 300,
        complete: function () {
          $(this).removeClass('show');
          $(this).css('left', '100%');
        }
      }
    );
  } else if(flagPageType == 'fade'){

    // 次ページを表示
    elePageWrapInner.animate(
      {height: numNextPageHeight + 'px'},
      {
        duration: 300,
        complete: function (){
          eleNextPageItem.addClass('show');
          eleNextPageItem.css('visibility', 'visible');
          eleNextPageItem.animate(
            {opacity: 1},
            {duration: 300}
          );
        }
      }
    );

    // 現ページを非表示表示
    eleCurrentPageItem.animate(
      {opacity: 0},
      {
        duration: 300,
        complete: function (){
          $(this).removeClass('show');
          $(this).css('visibility', 'collapse');
        }
      }
    );
  }
});

// サイドスライド関連の高さを設定
function globalNaviModalInit(eleTargetModal){
  let windowWidth= $(window).width(),
      flagModalType = eleTargetModal.attr('data-type'),
      eleModalInner = eleTargetModal.find('.js-target-modal-inner'),
      eleLevel1 = eleModalInner.find('.js-level-1'),
      elePageItem = eleModalInner.find('.js-page-item'),
      eleSliderWrap = eleTargetModal.find('.js-slide-wrap'),
      numSlideCount = 0;
      if(windowWidth > 768){
        numSlideCount = eleTargetModal.attr('data-slide-width');
      } else {
        numSlideCount = windowWidth;
      }


  // 初期位置を設定
  eleTargetModal.attr("data-flag-level", 1);
  elePageItem.each(function (){
    let eleScrollItem = $(this).children('.js-scroll-wrap').children('.js-scroll-item'),
        numLevelHeight = eleScrollItem.innerHeight();
    eleScrollItem.css({
      'height': numLevelHeight
    });
    $(this).css({
      'visibility': 'collapse'
    });
    if($(this).hasClass('first-page')){
      $(this).css({
        'visibility': 'visible'
      });
    };
    $(this).attr('data-current-position', "-" + numSlideCount)
  });

  eleSliderWrap.attr('data-flag-level', 1);

  eleLevel1.each(function (){
    $(this).css({
      'visibility': 'visible'
    });
  });
  // アニメーションで表示
  eleLevel1.animate(
    {
      right: 0,
      opacity: 1
    },
    {
      duration: 320,
      complete: function (){
        eleLevel1.attr('data-current-position', 0);
        if(flagModalType == 'category'){
          eleTargetModal.css('backgroundColor', '#1F1D1D');
        } else if(flagModalType == 'filter'){
          eleTargetModal.css('backgroundColor', '#FFFFFF');
        }
      }
    }
  );
}

// クリック処理　次スライドへ
$('.js-trigger-slide').on('click', function (){
  triggerSlide($(this));
});

window.triggerSlide = function($this) {
  let windowWidth= $(window).width(),
    eleSlideWrap =  $this.closest('.js-slide-wrap'),
    eleTargetModal = $this.parents('.js-target-modal'),
    eleModalInner = eleTargetModal.find('.js-target-modal-inner'),
    eleActivePageItem = $this.next('.js-page-item'),
    elePrevPageItem = $this.closest('.js-page-item'),
    flagLevel = eleSlideWrap.attr('data-flag-level'),
    flagModalType = eleTargetModal.attr('data-type'),
    eleLevel1 = eleModalInner.find('.js-level-1'),
    eleLevel2 = eleSlideWrap.find('.js-level-2'),
    eleLevel3 = eleSlideWrap.find('.js-level-3'),
    eleLevel4 = eleSlideWrap.find('.js-level-4'),
    eleLevel5 = eleSlideWrap.find('.js-level-5'),
    numPageCount = eleSlideWrap.attr('data-page-count'),
    numSlideCount = 0;
    if(windowWidth > 768){
      numSlideCount = eleTargetModal.attr('data-slide-width');
    } else {
      numSlideCount = windowWidth;
    }

  // スライド時に操作不能にする
  $('.js-trigger-slide').css('pointerEvents', 'none');
  $('.js-trigger-page-prev').css('pointerEvents', 'none');
  setTimeout(function (){
    $('.js-trigger-slide').css('pointerEvents', 'auto');
    $('.js-trigger-page-prev').css('pointerEvents', 'auto');
  },500);

  $('.js-trigger-slide').removeClass('active');
  $(this).addClass('active');


  if(flagLevel == 2 && flagModalType == 'category'){

    // 最下層がLv2の場合
    eleTargetModal.attr("data-flag-level", 2);
    eleLevel2.css('zIndex', 0);
    eleActivePageItem.css({
      'visibility': 'visible',
      'zIndex': 1,
      'opacity': 1
    });

  } else {

    // 階層レベルを+1
    flagLevel = Number(flagLevel) + 1;
    if(flagLevel >= numPageCount){
      flagLevel = numPageCount;
    }
    eleSlideWrap.attr("data-flag-level", flagLevel);

    eleActivePageItem.css({
      'visibility': 'visible',
      'zIndex': 1,
      'opacity': 0
    });
    setTimeout(function (){
      eleActivePageItem.css({
        'opacity': 1
      });
    });

    // 前ページのアニメーション
    let numPrevSlidePos = elePrevPageItem.attr('data-current-position'),
        numPrevSlide = Number(numPrevSlidePos) + Number(numSlideCount);
    elePrevPageItem.animate(
      {right: numPrevSlide + 'px'},
      {
        duration: 240,
        easing: 'linear',
        complete: function (){
          if(flagModalType == 'filter'){
            elePrevPageItem.css({
              'visibility': 'collapse',
              'zIndex': 0
            });
          }
        }
      }
    );

    if(flagLevel == 2){
      eleLevel1.attr('data-current-position', numSlideCount);
      eleLevel1.css('right', numSlideCount);
    } else if(flagLevel == 3){
      eleLevel2.attr('data-current-position', numSlideCount);
      eleLevel2.css('right', numSlideCount);
      eleLevel1.attr('data-current-position', numSlideCount * 2);
      eleLevel1.css('right', numSlideCount * 2);
    } else if(flagLevel == 4){
      eleLevel3.attr('data-current-position', numSlideCount);
      eleLevel3.css('right', numSlideCount);
      eleLevel2.attr('data-current-position', numSlideCount * 2);
      eleLevel2.css('right', numSlideCount * 2);
      eleLevel1.attr('data-current-position', numSlideCount * 3);
      eleLevel1.css('right', numSlideCount * 3);
    } else if(flagLevel == 5){
      eleLevel4.attr('data-current-position', numSlideCount);
      eleLevel4.css('right', numSlideCount);
      eleLevel3.attr('data-current-position', numSlideCount * 2);
      eleLevel3.css('right', numSlideCount * 2);
      eleLevel2.attr('data-current-position', numSlideCount * 3);
      eleLevel2.css('right', numSlideCount * 3);
      eleLevel1.attr('data-current-position', numSlideCount * 4);
      eleLevel1.css('right', numSlideCount * 4);
    }

    // 現ページのアニメーション
    eleActivePageItem.animate(
      {right: 0},
      {
        duration: 240,
        easing: 'linear',
        complete: function () {
          eleActivePageItem.attr('data-current-position', 0);
          if(flagLevel == 2){
            eleLevel2.attr('data-current-position', 0);
            eleLevel2.css({
              'right': 0
            });
          } else if(flagLevel == 3){
            eleLevel3.attr('data-current-position', 0);
            eleLevel3.css({
              'right': 0
            });
          } else if(flagLevel == 4){
            eleLevel4.attr('data-current-position', 0);
            eleLevel4.css({
              'right': 0
            });
          } else if(flagLevel == 5){
            eleLevel5.attr('data-current-position', 0);
            eleLevel5.css({
              'right': 0
            });
          }
        }
      }
    );
  }
}

// クリック処理 前スライドへ
$('.js-trigger-page-prev').on('click', function (){
  triggerPagePrev($(this));
});

window.triggerPagePrev = function($this) {
  let eleActivePageItem,
      windowWidth= $(window).width(),
      eleSlideWrap =  $this.closest('.js-slide-wrap'),
      eleTargetModal = $this.parents('.js-target-modal'),
      flagModalType = eleTargetModal.attr('data-type'),
      numSlideCount = 0,
      flagLevel = 1,
      elePageItemAll = eleTargetModal.find('.js-page-item');
      if(windowWidth > 768){
        numSlideCount = eleTargetModal.attr('data-slide-width');
        numSlideWidth = numSlideCount + 'px';
      } else {
        numSlideCount = windowWidth;
        numSlideWidth = numSlideCount + 'px';
      }

  $('.js-trigger-slide').removeClass('active');

  flagLevel = eleSlideWrap.attr('data-flag-level');
  eleSlideWrap.attr("data-flag-level", Number(flagLevel) - 1);

  if(flagLevel == 2 && flagModalType == 'filter'){
    eleActivePageItem = $this.closest('.js-page-item').parent('dd').closest('.js-page-item');
  } else {
    eleActivePageItem = $this.closest('.js-page-item').parent('li').closest('.js-page-item');
  }
  let elePrevPageItem = $this.closest('.js-page-item');

  eleActivePageItem.css({
    'visibility': 'visible',
    'zIndex': 1,
    'opacity': 1
  });

  elePrevPageItem.animate(
    {right: '-' + numSlideWidth},
    {
      duration: 240,
      easing: 'linear',
      complete: function (){
        elePageItemAll.each(function (){
          let numCurrentPos = $this.attr('data-current-position'),
            numNextPos = Number(numCurrentPos) - Number(numSlideCount);
          if(numNextPos < '-' + numSlideCount) {
            numCurrentPos = '-' + numCurrentPos;
          }
          $this.css('right', Number(numCurrentPos) - Number(numSlideCount));
          $this.attr('data-current-position', Number(numCurrentPos) - Number(numSlideCount));
        });
        elePrevPageItem.css({
          'visibility': 'collapse',
          'zIndex': 0,
        });
      }
    }
  );

  eleActivePageItem.animate(
    {right: 0},
    {
      duration: 240,
      easing: 'linear'
    }
  );
}

// チェックして戻る
$('.js-trigger-checked').on('click', function (){
  triggerChecked($(this));
});

window.triggerChecked = function($this) {
  let txtCategory = $this.text(),
    windowWidth= $(window).width(),
    eleSlideWrap =  $this.closest('.js-slide-wrap'),
    eleTargetModal = $this.closest('.js-target-modal'),
    elePageItemAll = eleSlideWrap.find('.js-page-item'),
    elePrevPageItem = $this.closest('.js-page-item'),
    eleActivePageItem = $this.closest('.js-level-1'),
    eleSubFilter = eleSlideWrap.find('.js-insert-sub-filter'),
    eleTargetButton = eleSlideWrap.find('.js-target-insert'),
    flagLevel = 1,
    numSlideCount = 0;
  if(windowWidth > 768){
    numSlideCount = eleTargetModal.attr('data-slide-width');
  } else {
    numSlideCount = windowWidth;
  }
  eleTargetButton.text(txtCategory);
  eleSubFilter.css('display','block');

  toggleTagMore(windowWidth);

  eleSlideWrap.attr("data-flag-level", flagLevel);

  setTimeout(function (){

    elePrevPageItem.css({
      'right': '-' + numSlideCount
    });

    elePageItemAll.attr('data-current-position', '-' + numSlideCount);
    eleActivePageItem.attr('data-current-position', 0);
    eleTargetModal.attr('data-flag-level', 1);
    elePageItemAll.css({
      'right': '-' + numSlideCount + 'px',
      'visibility': 'collapse',
      'zIndex': 0,
    });
    eleActivePageItem.css({
      'right': '0px',
      'zIndex': 1,
      'visibility': 'visible',
      'opacity': 1
    });

  },240);
}

// フォーム内容をリセット
$('.js-reset-input').on('click', function (){
  resetInput($(this));
});

window.resetInput = function($this) {
  let eleTargetModal = $this.closest('.js-target-modal'),
      eleInsertButton = eleTargetModal.find('.js-target-insert'),
      eleInsertBody = eleTargetModal.find('.js-insert-sub-filter'),
      txtInsertText = eleInsertButton.attr('data-text');

  eleTargetModal.find("textarea, :text,  select").val("");
  eleTargetModal.find("[type=number]").val("");
  eleTargetModal.find("[type=search]").val("");
  eleTargetModal.find(":checked").prop("checked", false);

  eleInsertButton.text(txtInsertText);
  eleInsertBody.css({
    'display':'none'
  });
}

// フィルダーの検索履歴モーダルの展開
$('.js-input-search1').on('click', function (){
  let  windowWidth= $(window).width(),
      eleSearchPointWrap = $(this).closest('.js-search-point-wrap'),
      flagModalType = eleSearchPointWrap.attr('data-type'),
      eleSearchHistory;
      if(flagModalType === "search"){
        let eleSearchHistoryWrap = eleSearchPointWrap.next('#gnav-search');
        eleSearchHistory = eleSearchHistoryWrap.find('.js-search-history-modal');
      } else {
        eleSearchHistory = eleSearchPointWrap.find('.js-search-history-modal');
      }
  let eleInputSearch2 = eleSearchHistory.find('.js-input-search2'),
      eleScrollWidth = eleSearchHistory.find('.js-scroll-width'),
      eleTagItem = eleScrollWidth.find('li'),
      numTagWidth = 0,
      numTagWidth2 = 0,
      numTagItem = 0,
      i = 0;

  if(windowWidth < 768) {
    eleTagItem.each(function () {
      i++;
      numTagItem = $(this).innerWidth();
      numTagWidth += numTagItem;
      if(i === 5){
        //$(this).after('<li class="br"></li>');
        numTagWidth2 = numTagWidth;
      }
    });
    if(i >= 5){
      eleScrollWidth.css({
        'width': numTagWidth2 + 60 + 'px'
      });
    }
  }

  eleSearchHistory.css({
    'visibility':'visible',
    'opacity': 1
  });
  eleInputSearch2.focus();

});

// 検索履歴モーダルの内容を残して閉じる
$('.js-trigger-search-ok').on('click', function (){
  let eleWrapItem = $(this).closest('.js-target-modal'),
      eleInputSearch1 = eleWrapItem.find('.js-input-search1'),
      eleInputSearch2 = eleWrapItem.find('.js-input-search2'),
      eleSearchWrap = eleWrapItem.find('.js-search-history-modal'),
      eleTagWrap = eleSearchWrap.find('.js-scroll-width'),
      txtSearchWord = eleInputSearch2.val();

  eleInputSearch1.val(txtSearchWord);
  eleSearchWrap.css({
    'visibility':'collapse',
    'opacity': 0
  });
  eleTagWrap.css('width','');
});

// 検索履歴モーダルの内容をクリアして閉じる
$('.js-trigger-search-cancel').on('click', function (){
  let eleWrapItem = $(this).closest('.js-target-modal'),
    eleInputSearch1 = eleWrapItem.find('.js-input-search1'),
    eleSearchWrap = eleWrapItem.find('.js-search-history-modal'),
    eleTagWrap = eleSearchWrap.find('.js-scroll-width'),
    txtSearchWord = "";

  eleInputSearch1.val(txtSearchWord);
  eleSearchWrap.css({
    'visibility':'collapse',
    'opacity': 0
  });
  eleTagWrap.css('width','');
});



// 検索候補を入力フィールドに挿入
$('.js-trigger-input-search').on('click', function (){
  let eleWrapItem = $(this).closest('.js-target-modal'),
      eleSearchWrap = eleWrapItem.find('.js-search-history-modal'),
      eleInputSearch2 = eleSearchWrap.find('.js-input-search2'),
      txtSearchWord = $(this).attr('data-text');

  eleInputSearch2.val(txtSearchWord);

});

// 検索フォームに文字が入った場合候補を表示する
$('.js-input-search2').on('input', function (){
  let txtSearchWord = $(this).val(),
      eleSearchWrap = $(this).closest('.js-search-history-modal'),
      elePoint = eleSearchWrap.find('.js-search-point'),
      eleChoice = eleSearchWrap.find('.js-search-choice');

  if(txtSearchWord){
    elePoint.css('display', 'none');
    eleChoice.css('display', 'block');
  } else{
    elePoint.css('display', 'block');
    eleChoice.css('display', 'none');
  }


});

//
// グローバルナビのマイページモーダル
//
// hover時の処理

function gnavSubnav(windowWidth){
  const flagLogin = $('html').attr('data-login');

  // ログインの判定
  if(flagLogin == 'true'){
    // PCの場合Hover時にモーダル展開
    if(windowWidth > 768){
      $('.js-trigger-gnav-subnav').on({
        'mouseenter': function() {
          let $this = $(this);
          openModal($this);
        },
        'mouseleave': function() {
          let txtModalName = $(this).attr('data-close-modal');
          closeModal(txtModalName);
        }
      })
      // SPの場合Click時にモーダル展開
    } else {
      $('.js-trigger-gnav-subnav').on('click', function (){
        let $this = $(this),
          eleTargetModal = $this.find('.js-target-modal'),
          flagModal = $this.hasClass('on');

        if(flagModal){
          let txtModalName = $(this).attr('data-close-modal');
          closeModal(txtModalName);
        } else {
          setTimeout(function (){
            openModal($this);
          },200);
          return false;
        }
      });
    }
  }

}


$('.js-trigger-count').on('click', function (){
  let flagType = $(this).attr('data-type'),
    eleCounterWrap = $(this).closest('.js-counter'),
    eleInput = eleCounterWrap.find('.js-counter-input'),
    numCurrentCount = eleInput.val(),
    numMaxStock = eleCounterWrap.attr('data-stock');

  if(flagType === 'minus'){
    if(numCurrentCount > 0){
      eleInput.val(numCurrentCount - 1);
      if(numCurrentCount === 1){
        $(this).css({
          'opacity': 0.3,
          'pointerEvents': 'none'
        })
      } else {
        $(this).css({
          'opacity': 1,
          'pointerEvents': ''
        })
      }
    }
    if(numMaxStock !== 0 && numCurrentCount <= numMaxStock){
      eleCounterWrap.find('.js-trigger-count[data-type="plus"]').css({
        'opacity': 1,
        'pointerEvents': ''
      });
    }
  } else if(flagType === 'plus'){
    if(numCurrentCount < Number(numMaxStock)){
      numCurrentCount = Number(numCurrentCount) + 1;
      eleInput.val(numCurrentCount);
      if(numCurrentCount == numMaxStock){
        $(this).css({
          'opacity': 0.3,
          'pointerEvents': 'none'
        })
      } else {
        $(this).css({
          'opacity': 1,
          'pointerEvents': ''
        });
        if(numCurrentCount > 0){
          eleCounterWrap.find('.js-trigger-count[data-type="minus"]').css({
            'opacity': 1,
            'pointerEvents': ''
          });
        }
      }
    }
  }

});


function modalAutoVisible(){
  let flagAutoVisible = $('html').attr('data-auto-visible'),
    flagModalType = $('html').attr('data-auto-modal-id'),
    eleTriggerButton = $('.js-trigger-modal[data-modal=' + flagModalType + ']');

  if(flagAutoVisible === 'visible'){
    eleTriggerButton.click();
  }

}

//
// スターレート
//
$('.js-input-star-rate i').on('click', function (){
  let eleWrap = $(this).closest('.js-input-star-rate'),
    numRate = $(this).attr('data-rate'),
    eleTargetInput = eleWrap.next('input[type=hidden]');

  eleWrap.removeClass('star-1 star-2 star-3 star-4 star-5');
  eleWrap.addClass('star-' + numRate);
  eleTargetInput.val(numRate);
});

//
// テキストフォームの文字数カウント
//
$('.js-trigger-count-length').on('input', function (){
  let eleWrap = $(this).closest('.js-count-length'),
    eleTargetWrap = eleWrap.find('.js-target-count-length'),
    eleTargetCount = eleTargetWrap.find('.js-current-length'),
    numMaxCount = eleTargetWrap.attr('data-max-length'),
    numCurrentCount = $(this).val().length;

  eleTargetCount.text(numCurrentCount);
  if(numMaxCount < numCurrentCount){
    eleTargetCount.addClass('over');
  } else{
    eleTargetCount.removeClass('over');
  }
});

//
// ファイルアップロードの画像変更
//
$('.js-trigger-file-upload').on('click', function (){
  let eleInputFile = $(this).next('.block-file-upload').find('.js-input-file');
  eleInputFile.click();
});

$(function(){
  $('.js-input-file').change(function(e){
    let file = e.target.files[0];
    let reader = new FileReader();
    reader.onload = (function(file){
      return function(e){
        $('.js-file-image').attr('src', e.target.result);
        $('.js-file-image').attr('srcset', e.target.result);
        $('.js-file-image').attr('title', file.name);
      };
    })(file);
    reader.readAsDataURL(file);
  });
});

//
// Radio
//
$('.js-trigger-radio-switch').on('change', function (){
  let eleItem = $(this).closest('.js-radio-switch'),
      flagRadioType = $(this).hasClass('js-on');
  if(flagRadioType === true){
    if($(this).prop('checked')){
      eleItem.addClass('on');
    }
  } else {
    eleItem.removeClass('on');
  }
});

//
// 非表示要素の表示
//
$('.js-trigger-input-toggle').on('change', function (){
  let eleItem = $(this).closest('.js-input-toggle-item');
  let eleWrap = eleItem.closest('.js-toggle-input-wrap');
  let eleItemAll = eleWrap.find('.js-input-toggle-item');
  let eleItemBody, numItemBody;
  if($(this).prop('checked')){
    eleItemAll.removeClass('on');
    eleItem.addClass('on');
  }else{
    eleItem.removeClass('on');
  }
});

//
// ツールチップ表示
//
$('.js-trigger-tooltip').on('click', function (){
  let windowWidth = $(window).width(),
    eleWrap = $(this).closest('.js-tooltip-wrap');
  tooltipPosition(windowWidth);
  eleWrap.toggleClass('on');
});


// ツールチップを閉じる
$('.js-trigger-tooltip-close').on('click', function (){
  let eleWrap = $(this).closest('.js-tooltip-wrap'),
    eleTarget = eleWrap.find('.js-target-tooltip');
  eleWrap.removeClass('on');
  eleTarget.attr('style','');
});

// スマホ時にツールチップの位置調整
window.tooltipPosition = function(windowWidth){
  if(windowWidth < 576) {
    let eleTrigger = $('.js-trigger-tooltip'),
        eleTarget,
        flagType,
        numLeftPos;
    eleTrigger.each(function (){
      eleTarget = $(this).next('.js-target-tooltip');
      flagType = eleTarget.attr('data-type');
      if(flagType === "fill-sm"){
        numLeftPos = $(this).offset().left;
        eleTarget.css('margin-left', '-' + numLeftPos + 'px');
      }
    });
  }
}

// アンカースクロール
$('.js-anchor-scroll').click(function(){
  let numAdjust = 0;
  let numSpeed = 400;
  let href= $(this).attr("href");
  let target = $(href == "#" || href == "" ? 'html' : href);
  let position = target.offset().top + numAdjust;
  let numScrollPadding = $(this).data('scroll-padding');
  if(numScrollPadding){
    position = position - numScrollPadding;
  }
  $('body,html').animate({scrollTop:position}, numSpeed, 'swing');
  return false;
});

// スクロールスパイ(各要素の高さを取得)
window.scrollspyInit = function(){
    let eleTargetWrap, eleTargetItems,
        numTargetTop, numTargetBottom,
        numTargetWrapTop, numTargetWrapBottom;
    eleTargetWrap = $(".js-scrollspy-target");
    eleTargetItems = eleTargetWrap.find(".js-scrollspy-item");

    eleTargetItems.each(function (){
        numTargetTop = $(this).offset().top;
        numTargetBottom = numTargetTop + $(this).outerHeight();
        $(this).attr('data-scrollspy-top', numTargetTop);
        $(this).attr('data-scrollspy-bottom', numTargetBottom);
    });

    // サイト内ナビの表示・非表示の初期設定
    numTargetWrapTop = eleTargetWrap.offset().top;
    numTargetWrapBottom = numTargetWrapTop + eleTargetWrap.outerHeight();
    eleTargetWrap.attr('data-scrollspy-top', numTargetWrapTop);
    eleTargetWrap.attr('data-scrollspy-bottom', numTargetWrapBottom);
}

    // スクロールスパイ(スクロール時の挙動)
window.scrollspyScroll = function() {
    let numCurrentScroll,
        eleTargetWrap, eleTargetItems,
        eleTriggerWrap,
        numTargetTop, numTargetBottom, numScrollPadding,
        numTargetWrapTop, numTargetWrapBottom,
        i, a;
    numCurrentScroll = $(this).scrollTop();

    eleTargetWrap = $(".js-scrollspy-target");
    eleTargetItems = eleTargetWrap.find(".js-scrollspy-item");
    eleTriggerWrap = $(".js-scrollspy-nav");

    setTimeout(function (){
        let x = eleTargetWrap.offset().top;
    }, 1000);
    eleTargetItems.each(function (){
        i = $(this).attr('id');
        a = eleTriggerWrap.find(".js-anchor-scroll[href='#" + i + "']");
        numScrollPadding = a.data('scroll-padding');
        numTargetTop = $(this).data('scrollspy-top') - numScrollPadding - 1;
        numTargetBottom = $(this).data('scrollspy-bottom') - numScrollPadding - 1;
        if(numTargetTop < numCurrentScroll && numTargetBottom > numCurrentScroll) {
        a.addClass('active');
        } else{
        a.removeClass('active');
        }
    });

    // サイト内ナビの表示・非表示
    numTargetWrapTop = eleTargetWrap.data('scrollspy-top') - numScrollPadding - 1;
    numTargetWrapBottom = eleTargetWrap.data('scrollspy-bottom') - numScrollPadding - 1;
    if(numTargetWrapTop < numCurrentScroll && numTargetWrapBottom > numCurrentScroll) {
        eleTriggerWrap.addClass('active');
    } else{
        eleTriggerWrap.removeClass('active');
    }
}

// History Back
$('.js-history-back').on('click', function (){
  window.history.back();
  return false;
});

// Handing Broken Image of Submit Recommend
$(function () {
  setTimeout(function () {
    $("#submit-personalize .product-thumb-image img, #submit-history .product-thumb-image img, #submit-recommend .product-thumb-image img").on("error", function () {
      $(this).attr("error", null);
      $(this).attr("srcset", "/html/upload/save_image/no-image.jpg");
    });

    $("#submit-personalize .block-comment img, #submit-history .block-comment img, #submit-recommend .block-comment img").on("error", function () {
      $(this).attr("error", null);
      $(this).attr("srcset", "/html/upload/save_image/no-image-small.jpg");
    });
  }, 1000);
});

// reset the form data when the inquiry is completed
window.resetFormDataInquiry = function() {
  window.addEventListener("pageshow", function(event) {
    var historyTraversal = event.persisted ||
        (typeof window.performance != 'undefined' &&
            window.performance.navigation.type === 2);
    if (historyTraversal) {
      this.window.location.replace(this.location.href);
    }
  });
}

//
// 汎用toggle
//
// 初期設定
window.toggleDefault = function(flagDetail) {
  if (flagDetail) {
    $('dl.js-toggle-default').each(function() {
      const eleTrigger = $(this).find(".js-toggle-trigger");
      const eleTarget = $(this).find(".js-toggle-target");
      const eleTargetInner = $(this).find('.js-toggle-target-inner');
      let divHeight;
      eleTarget.css({
          'overflow' : 'hidden',
          'transition' : '.32s 0s'
      });
      divHeight = eleTargetInner.outerHeight(true);
      eleTarget.attr('data-height', divHeight);
      if(eleTrigger.hasClass('expanded')) {
          eleTarget.css({
          'height' : divHeight + 'px'
          });
      } else {
          eleTarget.css({
          'height' : 0 + 'px'
          });
      }
    });
  } else {
    const eleWrap = $('.js-toggle-default');
    const eleTrigger = eleWrap.find(".js-toggle-trigger");
    const eleTarget = eleWrap.find(".js-toggle-target");
    const eleTargetInner = eleTarget.children(".js-toggle-target-inner");
    let numTargetHeight;
    eleTarget.css({
      'overflow' : 'hidden',
      'transition' : '.32s 0s'
    });
    numTargetHeight = eleTargetInner.outerHeight(true);
    eleTarget.attr('data-height', numTargetHeight);
    if(eleTrigger.hasClass('expanded')) {
      eleTarget.css({
        'height' : numTargetHeight + 'px'
      });
    } else {
      eleTarget.css({
        'height' : 0 + 'px'
      });
    }
  }
}

// クリック時の処理
$('.js-toggle-trigger').on('click', function (){
  const eleWrap = $(this).closest('.js-toggle-default');
  const eleTarget = eleWrap.children(".js-toggle-target");
  let numTargetHeight, txtToggleText, txtToggleCurrentText;
  numTargetHeight = eleTarget.attr('data-height');
  if($(this).hasClass('expanded')){
    eleTarget.css('height', 0 + 'px');
    txtToggleText = $(this).attr('data-toggle-text');
    txtToggleCurrentText = $(this).text();
    $(this).text(txtToggleText);
    // use data-toggle-text attribute only on feature page in case of 'もっと見る'
    if (txtToggleCurrentText == "閉じる" || txtToggleCurrentText == "もっと見る") {
      $(this).attr('data-toggle-text', txtToggleCurrentText);
    }
    $(this).removeClass('expanded');
  } else{
    eleTarget.css('height', numTargetHeight + 'px');
    txtToggleText = $(this).attr('data-toggle-text');
    txtToggleCurrentText = $(this).text();
    $(this).text(txtToggleText);
    // use data-toggle-text attribute only on feature page in case of 'もっと見る'
    if (txtToggleCurrentText == "閉じる" || txtToggleCurrentText == "もっと見る") {
      $(this).attr('data-toggle-text', txtToggleCurrentText);
    }
    $(this).addClass('expanded');
  }

  if($('body').has('#page_product_detail')){
    setTimeout(function (){
      let windowWidth= $(window).width();
      stickyProdctDetailRight(windowWidth);
    }, 300);
  }
});

// Toggle自体を表示する条件
window.toggleDefaultVisible = function() {
  const eleWrap = $('.js-toggle-default-visible');
  const eleItem = eleWrap.find('.js-toggle-visible-item');
  const eleTarget = eleWrap.find('.js-toggle-visible-target');
  let numCurrentCount, numVisibleCount;
  numCurrentCount = Number(eleItem.attr('data-current-list-count'));
  numVisibleCount = Number(eleItem.attr('data-visible-list-count'));
  if(numCurrentCount <= numVisibleCount){
    eleTarget.css("display", "none");
  }
}

//
// Flash Message の閉じる
//
$(".js-trigger-flash-message-close").on('click', function (){
  let txtId = $(this).attr('data-close');
  $("#"+txtId).hide();
});

//
// カテゴリーをクリック
//
$(".js-trigger-click-category").on('click', function (){
  let refererSide = $(this).attr('data-referer-side');
  setTimeout(function (){
    $(".js-target-click-category").click();

    // Add GA measurement param for category
    $(".measurement-param").each(function () {
      var currentHref = $(this).attr("href");
      if (currentHref.indexOf('?') !== -1) {
        currentHref = currentHref.split('?')[0];
      }
      $(this).attr("href", currentHref + '?' + refererSide);
    });
  },100);
});

// Add GA measurement param for category
$(".js-target-click-category").on('click', function (){
  let refererNav = $(this).attr('data-referer-nav');

  $(".measurement-param").each(function () {
    var currentHref = $(this).attr("href");
    if (currentHref.indexOf('?') !== -1) {
      currentHref = currentHref.split('?')[0];
    }
    $(this).attr("href", currentHref + '?' + refererNav);
  });
});

//
// 20歳未満の誕生日チェック
//
// 日付の妥当性チェック
window.isDate = function(strDate){
  if(strDate == ""){
    return true;
  }
  if(!strDate.match(/^\d{4}-\d{1,2}-\d{1,2}$/)){
    return false;
  }
  let date = new Date(strDate);
  if(date.getFullYear() !=  strDate.split("-")[0] || date.getMonth() != strDate.split("-")[1] - 1 || date.getDate() != strDate.split("-")[2] ){
    return false;
  }
  return true;
}

window.checkBirthday = function(){
  const eleWrap = $(".js-check-birthday");
  const eleTarget = eleWrap.find(".js-check-birthday-target");
  let arrayTarget = eleWrap.find(".js-check-birthday-item");
  let eleYear = eleWrap.find(".js-check-birthday-year");
  let eleMonth = eleWrap.find(".js-check-birthday-month");
  let eleDay = eleWrap.find(".js-check-birthday-day");
  let dateBirthday = null;
  let valYear, valMonth, valDay, flagDate;
  let dateNow = new Date().toLocaleDateString('sv-SE');
  let diffDate = 0;
  let limitDay = 7305; // 20年間を日数に直した数値
  arrayTarget.on("blur", function (){
    setTimeout(function (){
      valYear = eleYear.val();
      valMonth = eleMonth.val();
      valDay = eleDay.val();
      valMonth = valMonth.toString().padStart(2, '0');
      valDay = valDay.toString().padStart(2, '0');
      dateBirthday = valYear + "-" + valMonth + "-" + valDay;
      flagDate = isDate(dateBirthday);
      if(flagDate === true){
        dateNow = new Date(dateNow);
        dateBirthday = new Date(dateBirthday);
        diffDate = dateNow - dateBirthday;
        diffDate =diffDate / (1000 * 60 * 60 * 24);
        if(limitDay <= diffDate){
          eleTarget.prop('disabled',false);
        } else {
          eleTarget.prop('disabled',true);
        }
      } else {
        eleTarget.prop('disabled',true);
      }
    },10);
  });
}
//
// Input Fieldの入力制限
//
// 入力を数字のみに制限
function toHalfWidth(str) {
  // 全角英数字を半角に変換
  str = str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function(s) {
    return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
  });
  return str;
}
window.validateNumberInput = function(){
  let eleItem = $(".js-input-limited-number");
  let valItem;
  eleItem.on("blur", function() {
    valItem = $(this).val();
    valItem = toHalfWidth(valItem);
    $(this).val(valItem.replace(/[^0-9]/g, ""));
  });
}

//
// アンカーリンクの遅延読み込み
//
window.anchorLazyLoad = function() {
    const txtHash = location.hash;
    if(txtHash){
      const numOffsetTop = $(txtHash).offset().top;
      setTimeout(function (){
        $("html,body").scrollTop(numOffsetTop - 160);
        //$("html,body").animate({scrollTop:numOffsetTop - 158});
      }, 100);
      return false;
    }
}

//
// Set all feature url into cookie
//
function setFeaturesToCookie() {
  let url = window.location.href;
  let currentUrl = url.indexOf('?') != -1 ? url.substring(0, url.indexOf('?')) : url;

  if (currentUrl.includes('/special/') || currentUrl.includes('/article/')) {
    let recentViewArr = JSON.parse(Cookies.get('recentViewArr') || '[]');

    if (recentViewArr.includes(currentUrl)) {
      recentViewArr.splice(recentViewArr.indexOf(currentUrl), 1);
    }

    recentViewArr.unshift(currentUrl);
    Cookies.set('recentViewArr', JSON.stringify(recentViewArr));
  }
}

// クーポンタブの表示ON/OFF制御
function setConditionOfCouponTab(){
    // footer 要素を取得
    const footer = document.querySelector('footer');
    if(footer){
      // クーポンタブを取得
      const couponTab = document.getElementById('id-btn-fixed-coupon-tab');
      if(couponTab){
        // クーポンタブの情報を取得
        const rect = couponTab.getBoundingClientRect();
        // ページ上端からクーポンタブの下端までの距離を計算
        const distanceFromTopToBottomOfCouponTab = window.scrollY + rect.bottom;
        // ページの上端から footer の上端までの距離を取得
        const distanceFromTopToFotter = footer.getBoundingClientRect().top + window.scrollY;
        // クーポンタブがフッター領域に重なる場合は非表示にする
        if(distanceFromTopToBottomOfCouponTab < distanceFromTopToFotter){
          couponTab.style.visibility = 'visible';
          couponTab.style.opacity = 1;
        } else {
          couponTab.style.visibility = 'collapse';
          couponTab.style.opacity = 0;
        }
      }
    }
  }
  // MutationObserverを初期化
  // ・ページを下端までスクロールさせた状態で、ページをリロードすると footer上にクーポンタブが表示されてしまう。
  // ・これを回避するため、window.loadイベントでクーポンタブの表示On/Offを判定したが、それ以降に動的ページ生成(？)によりページ高さが変わり、上記の現象が発生していた。
  // ・これを回避するため、body要素の高さ変更を検出した時点で、クーポンタブの表示On/Offを判定するように、以下の実装を追加した。
  // ※正しい実装方法は？
  //   動的ページ生成が完了した時点で、setConditionOfCouponTab() をコールすればよい。
  //   現時点では、このイベントの捕捉方法が分からないため、現状の実装としている。 
  const observerForCouponTab = new MutationObserver(function (){
    setTimeout(setConditionOfCouponTab, 500);
  });
  observerForCouponTab.observe(document.body, {childList: true, subtree: true});

//
// inputとtextareaの入力状況を確認する
//
document.addEventListener("DOMContentLoaded", function() {
  var inputs = document.getElementsByClassName('js-form-placeholder');

  // 初期状態の確認（ページロード時にすでに入力があるかどうか）
  Array.from(inputs).forEach(function(input) {
    if (input.value.trim() !== "") {
      input.classList.add("not-empty");
    }
    // 入力があるかどうかを監視
    input.addEventListener("input", function() {
      if (this.value.trim() !== "") {
        this.classList.add("not-empty");
      } else {
        this.classList.remove("not-empty");
      }
    });
  });
});
